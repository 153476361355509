import createLucideIcon from '../createLucideIcon';

const Loader = createLucideIcon('Loader', [
  ['line', { x1: '12', x2: '12', y1: '2', y2: '6', key: 'gza1u7' }],
  ['line', { x1: '12', x2: '12', y1: '18', y2: '22', key: '1qhbu9' }],
  ['line', { x1: '4.93', x2: '7.76', y1: '4.93', y2: '7.76', key: 'xae44r' }],
  [
    'line',
    { x1: '16.24', x2: '19.07', y1: '16.24', y2: '19.07', key: 'bxnmvf' },
  ],
  ['line', { x1: '2', x2: '6', y1: '12', y2: '12', key: '89khin' }],
  ['line', { x1: '18', x2: '22', y1: '12', y2: '12', key: 'pb8tfm' }],
  ['line', { x1: '4.93', x2: '7.76', y1: '19.07', y2: '16.24', key: '1uxjnu' }],
  ['line', { x1: '16.24', x2: '19.07', y1: '7.76', y2: '4.93', key: '6duxfx' }],
]);

export default Loader;
